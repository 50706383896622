@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: irsans-b;
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/irsans-b.eot);
  src: url(../fonts/irsans-b.eot?#iefix) format("embedded-opentype"), url(../fonts/irsans-b.woff) format("woff"),
    url(../fonts/irsans-b.ttf) format("truetype");
}

@font-face {
  font-family: irsans-m;
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/irsans-m.eot);
  src: url(../fonts/irsans-m.eot?#iefix) format("embedded-opentype"), url(../fonts/irsans-m.woff) format("woff"),
    url(../fonts/irsans-m.ttf) format("truetype");
}

@font-face {
  font-family: irsans-l;
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/irsans-l.eot);
  src: url(../fonts/irsans-l.eot?#iefix) format("embedded-opentype"), url(../fonts/irsans-l.woff) format("woff"),
    url(../fonts/irsans-l.ttf) format("truetype");
}

@font-face {
  font-family: irsans-u;
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/irsans-u.eot);
  src: url(../fonts/irsans-u.eot?#iefix) format("embedded-opentype"), url(../fonts/irsans-u.woff) format("woff"),
    url(../fonts/irsans-u.ttf) format("truetype");
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  width: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a5b4fc;
  border-radius: 8px;
  -moz-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6366f1;
  -moz-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.font-medium {
  font-family: irsans-m;
}
.font-light {
  font-family: irsans-u;
}
.font-bold {
  font-family: irsans-b;
}
.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
.font-serif {
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
}

body {
  margin: 0;
  font-family: irsans-l;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.switch {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 30px;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../images/brightness-high-fill.svg);

  background-size: 18px 18px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.clear-link:hover {
  text-decoration: none;
}

input:focus + .slider {
  box-shadow: 0 0 0px transparent;
}

.slider.rounded-full::before {
  border-radius: 2rem;
}

input:checked + .slider::before {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateX(26px) rotate(360deg);
  -ms-transform: translateX(26px) rotate(360deg);
  transform: translateX(26px) rotate(360deg);
  background-image: url(../images/moon.svg);
}

.dark .slider::before {
  background-color: #111827;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.slider::before {
  background-color: #ffffff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.drawer.hide {
  transform: translateX(100%);
}
.drawer.show {
  transform: translateX(0);
}

.signup-image {
  background-image: url("../images/signup-light.svg");
  background-position: center;
  background-clip: border-box;
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
}

.dark .signup-image {
  background-image: url("../images/signup-dark.svg");
  background-position: center;
  background-clip: border-box;
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
}

.rtl {
  direction: rtl;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.no-scroll main {
  overflow: hidden !important;
}

.loading {
  background-color: rgba(255, 255, 255, 0.9);
}
.dark .loading {
  background-color: rgba(31, 41, 55, 0.9);
}

.loader {
  border: 4px solid rgba(37, 99, 235, 0.2);
  border-top-color: rgba(37, 99, 235, 1) !important;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}
.dark .loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top-color: rgb(255, 255, 255, 1) !important;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.drawer {
  min-width: 320px;
}

.sl-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dark .highcharts-title {
  fill: #6366f1 !important;
  color: #6366f1 !important;
}
.highcharts-root {
  font-family: irsans-l !important;
}

.highcharts-plot-line-label {
  font-family: irsans-b !important;
  margin-top: -4px !important;
  padding-left: 1rem;
  opacity: 0.6;
}
.highcharts-grid-line {
  stroke: rgba(0, 0, 0, 0.2);
}

.dark .highcharts-grid-line {
  stroke: rgba(255, 255, 255, 0.2);
}

.highcharts-axis-labels.highcharts-yaxis-labels text {
  font-family: irsans-b !important;
  font-size: 12px !important;
}
.highcharts-axis-labels.highcharts-xaxis-labels text {
  font-family: irsans-m !important;
  font-size: 12px !important;
  transform: translate(0, 6px) !important;
}
.highcharts-axis.highcharts-yaxis text {
  font-size: 14px;
  font-family: irsans-m;
  color: #6366f1 !important;
  fill: #6366f1 !important;
}
.dark .highcharts-axis.highcharts-yaxis text {
  font-size: 14px;
  font-family: irsans-m;
  color: #6366f1 !important;
  fill: #6366f1 !important;
}

.dark .highcharts-axis-labels.highcharts-yaxis-labels text,
.dark .highcharts-axis-labels.highcharts-xaxis-labels text {
  fill: rgba(255, 255, 255, 0.75) !important;
}
.highcharts-plot-line-label {
  color: rgb(254, 55, 0);
}
.highcharts-axis-title {
  fill: rgba(37, 99, 235, 1) !important;
  color: rgba(37, 99, 235, 1) !important;
  font-size: 14px;
  font-family: irsans-m !important;
}
.highcharts-credits {
  display: none;
}

.Calendar {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}
.Calendar__weekDays .Calendar__weekDay {
  color: #6366f1 !important;
  font-size: 14px !important;
  font-family: irsans-m !important;
}

.dark .bg-gray-850 {
  background-color: rgba(31, 41, 55, 0.25);
}

.no-print {
  transform: none;
}

@media print {
  @page {
    margin: 0mm;
  }
  .no-print {
    display: none !important;
  }
  .print-border{
    border-bottom: solid 1px #000000 !important;
    border-radius: 0 !important;
    margin: 0 !important;
  }
}
